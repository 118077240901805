body {
  margin: 0;
  overflow-y: scroll;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

button {
  &:hover {
    cursor: pointer;
  }
}

.soft-header {
  background-color: var(--accent-a3);
}

.radix-themes {
  --default-font-family: "Roboto", sans-serif;
  --heading-font-family: "Playfair Display", serif;
  --default-font-size: 14px;

  --space-8: 50px;
  --space-9: 100px;

  --container-3: 1060px;
  --container-4: 1920px;

  --gray-12: #001446;

  // for yellow <Code>
  --yellow-a9: #f9ae28;

  --code-padding-top: 3px;
  --code-padding-bottom: 3px;

  --accent-9: #001446;
  --accent-10: #062f93;

  --indigo-a11: #062f93;
}

@import "./buttons.scss";
@import "./checkboxes.scss";
@import "./toasts.scss";
