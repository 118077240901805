@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}

.ToastViewport {
  --viewport-padding: 25px;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;

  &.top-right {
    top: 0;
    right: 0;
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
  }

  &.top-left {
    top: 0;
    left: 0;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
  }
}

.ToastRoot {
  background-color: white;
  border-radius: 6px;
  border: 2px solid var(--accent-10);
  padding: 15px;
  display: grid;
  grid-template-areas: "title action" "description action";
  grid-template-columns: auto max-content;
  column-gap: 15px;
  align-items: center;

  &[data-state="open"] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state="closed"] {
    animation: hide 100ms ease-in;
  }

  &[data-swipe="move"] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe="cancel"] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe="end"] {
    animation: swipeOut 100ms ease-out;
  }

  .ToastTitle {
    grid-area: title;
    margin-bottom: 5px;
    font-weight: 500;
    color: var(--slate-12);
    font-size: 15px;
  }

  .ToastDescription {
    grid-area: description;
    margin: 0;
    color: var(--slate-11);
    font-size: 13px;
    line-height: 1.3;
  }

  .ToastAction {
    grid-area: action;
  }
}
