[class^="collage-"] {
  display: grid;
  position: relative;

  img.graphic {
    min-width: 100px;
    height: auto;
  }
}

.collage-3 {
  grid-template-columns: repeat(3, 1fr);
}

.collage-10 {
  grid-template-columns: repeat(10, 1fr);
}

.collage-12 {
  grid-template-columns: repeat(12, 1fr);
}

.graphic-lg {
  object-fit: contain;
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 720px) {
  .graphic-lg {
    max-width: 520px;
  }
}
