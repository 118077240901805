#Establish {
  overflow-x: hidden;

  #Image {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 232px;
  }
}

@media only screen and (min-width: 720px) {
  #Establish {
    display: grid;
    grid-template-columns: 1fr min(530px, 50vw) min(530px, 50vw) 1fr;

    #Title {
      grid-column: 2 / 3;
    }

    #Image {
      grid-column: 3 / 5;
      height: 100%;
    }
  }
}
