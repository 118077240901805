.rt-Button {
  white-space: nowrap;
}

.rt-variant-soft {
  background: none;
}

.rt-variant-outline {
  box-shadow: inset 0 0 0 1px var(--accent-9);
  border-color: var(--accent-9);
  color: var(--accent-9);
}
