$color: #062f93;

/* width */
::-webkit-scrollbar {
  width: var(--space-2);
}

/* Track */
::-webkit-scrollbar-track {
  background: lighten(desaturate($color, 75), 50%);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: desaturate($color, 50);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: desaturate($color, 25);
}
