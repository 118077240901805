.break-at-word {
  // forces only one word per line regardless of length
  word-spacing: 100vw;
}

.font-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.text-white {
  color: white;
}
