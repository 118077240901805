.CheckboxRoot {
  width: 25px;
  min-width: 25px;
  height: 25px;
  border: 1px solid var(--accent-9);
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckboxRoot:hover,
.CheckboxRoot:focus {
  border: 1px solid var(--yellow-a9);
}
