[class*="show-gt-"],
[class*="show-lt-"] {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .show-gt-md {
    display: block;
  }
}

@media only screen and (max-width: 1024px) {
  .show-lt-md {
    display: block;
  }
}
